import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { Statistics } from '@bestfit-team/bf-base-ui';

function cleanDemoData (demoDataObject) {
  const dataArray = [];

  demoDataObject.map(item => {
    let itemHold = item.data;

    dataArray.push({
      value: itemHold.header,
      caption: itemHold.subheader,
    })

  })
  return dataArray;
}

const BasicNeedsDemographics = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query BasicNeedsDemographicsQuery {
      BasicNeedsDemographicsContent: allAirtable(filter: {data: {section_title: {eq: "Students BestFit supports"}}}) {
        nodes {
          data {
            header
            subheader
          }
        }
      }
    }
  `);

  const cleanedData = cleanDemoData(copy.BasicNeedsDemographicsContent.nodes);

  // console.log('RENAME::::::::::: ' + JSON.stringify(cleanedData))

  return (
    <>
      <Statistics
        headingCopy={'BestFit supports students with a range of identities & responsibilities'}
        statsItems={cleanedData}
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}
      />
    </>
  )
}

export default BasicNeedsDemographics;
