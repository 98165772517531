import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SkeletalHero } from '@bestfit-team/bf-base-ui';

const BasicNeedsHero = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query BasicNeedsHeroQuery {
      myBasicNeedsHero: airtable(
        data: {Segment: {eq: "basic-needs-use-header"}}, 
        ) {
          data {
            header
            status
            subheader
            action
            Segment
            action_url
            alt_image_text
            aws_url__from_section_images_
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
      <SkeletalHero marginless
        variant='SevenFiveRatio'
        containerBackgroundColor='rgb(244, 244, 244)'
        // backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={
          copy.myBasicNeedsHero.data.header 
        }
        subheadingCopy={
          copy.myBasicNeedsHero.data.subheader 
        }
        buttonLabel={
          copy.myBasicNeedsHero.data.action 
        }
        buttonUrl={
          copy.myBasicNeedsHero.data.action_url 
        }
        heroImageUrl={
          copy.myBasicNeedsHero.data.aws_url__from_section_images_[0] 
        }
        heroImageAlt={
          copy.myBasicNeedsHero.data.alt_image_text 
        }
      />
  )
}

export default BasicNeedsHero;
