import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Announcement, Footer, GridTwo } from '@bestfit-team/bf-base-ui';
import Layout from "../../components/layout";
import BasicNeedsHero from "../../sections/basic-needs-support/BasicNeedsHero";
import BasicNeedsLogos from "../../sections/basic-needs-support/BasicNeedsLogos";
import BasicNeedsContactUs from "../../sections/basic-needs-support/BasicNeedsContactUs";
import BasicNeedsDemographics from "../../sections/basic-needs-support/BasicNeedsDemographics";
import BasicNeedsHowItWorks from "../../sections/basic-needs-support/BasicNeedsHowItWorks";

const BasicNeedsSupport = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Basic Needs Support'
    >
      <BasicNeedsHero />

      <GridTwo
        spansFullPage
        disableGutters 
        marginless
        heroBackgroundColor='var(--bf-core-teal)'
      >
        <p> </p>
      </GridTwo>
      
      <BasicNeedsHowItWorks />
      <BasicNeedsDemographics />
      <BasicNeedsContactUs />
      <BasicNeedsLogos />
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query BasicNeedsSupportPageContentQuery {
    
//   }
// `

export default BasicNeedsSupport;