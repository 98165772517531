import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { LeftAlignSolution } from '@bestfit-team/bf-base-ui';

function cleanStepData (demoDataObject) {
  const dataArray = [];

  demoDataObject.map(item => {
    let itemHold = item.data;

    dataArray.push({
      stepHeaderCopy: itemHold.header,
      stepSubheadingCopy: itemHold.subheader,
      stepHeroImageUrl: itemHold.aws_url__from_section_images_[0],
      stepHeroImageAlt: itemHold.header,
      ...itemHold
    })

  })
  return dataArray;
}

const BasicNeedsHowItWorks = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query BasicNeedsHowItWorksQuery {
      myBasicNeedsHowItWorks: allAirtable(
        filter: {data: {Segment: {regex: "/basic-needs-use-solution/g"}}}
        sort: {fields: data___Segment}
      ) {
        nodes {
          data {
            header
            subheader
            Segment
            section_title
            aws_url__from_section_images_
          }
        }
      }
    }
  `);

  const cleanedData = cleanStepData(copy.myBasicNeedsHowItWorks.nodes);

  // console.log('BN HOW IT WORKS:::::::: ' + JSON.stringify(cleanedData))
  return (
    <LeftAlignSolution marginless
      variant='SevenFiveRatio'
      containerBackgroundColor='rgb(244, 244, 244)'
    
      stepOneData={cleanedData[0]}
      stepTwoData={cleanedData[1]}
      stepThreeData={cleanedData[2]}
    />
  )
}

export default BasicNeedsHowItWorks;
